import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Silky smooth Piechart transitions with React and D3.js",
  "description": "Today, I finally figured out how to build smooth D3 arc transitions. 🎉",
  "date": "2018-03-09T08:00:00.000Z",
  "published": "2018-03-09T08:00:00.000Z"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`[`}{`caption id=`}{`"`}{`attachment_8254`}{`"`}{` align=`}{`"`}{`alignnone`}{`"`}{` width=`}{`"`}{`1024`}{`"`}{`]`}<img parentName="p" {...{
        "src": "/59ee3958dc6d90b57613447fc5ad251d/blog-wp-content-uploads-2018-03-transition-pie.gif",
        "alt": "A pie streaming in some data"
      }}></img>{` A pie streaming in some data`}{`[`}{`/caption]`}</p>
    <p>{`Today, I finally figured out how to build smooth D3 arc transitions. 🎉`}</p>
    <p>{`Got some help from Bostock`}{`'`}{`s `}<a parentName="p" {...{
        "href": "http://bl.ocks.org/mbostock/5100636"
      }}>{`commented arc tween`}</a>{` block and Andy Shora`}{`'`}{`s guide on `}<a parentName="p" {...{
        "href": "http://andyshora.com/tweening-shapes-paths-d3-js.html"
      }}>{`tweening custom shapes and paths in D3.js`}</a>{`.`}</p>
    <blockquote>
      <p parentName="blockquote">{`The single most `}{`\\`}{`important requirement to perform interpolation in D3.js is that the structure of A must match the structure of B.`}</p>
      <p parentName="blockquote">{`~ Andy Shora`}</p>
    </blockquote>
    <p>{`That quote made it click. D`}{`'`}{`oh! So obvious.`}</p>
    <h2 {...{
      "id": "why-arc-transitions-are-hard"
    }}>{`Why arc transitions are hard`}</h2>
    <p>{`You see, the problem with arc transitions is that their path definition has a funny shape. It looks like this:`}</p>
    <pre><code parentName="pre" {...{}}>{`M100.6434055594246,-99.8203632756589A8,8,0,0,1,112.2823856114007,-99.46188154973098A150,150,0,0,1,-104.56274177607584,107.54828233063364A8,8,0,0,1,-104.38099615277264,95.90520136696549L-64.39381262786019,59.38549403963366A8,8,0,0,1,-53.635344263429694,59.35696964757701A80,80,0,0,0,61.78081312913049,-50.82451307295977A8,8,0,0,1,62.30830828934212,-61.57007978883599Z
`}</code></pre>
    <p>{`If that doesn`}{`'`}{`t make sense, don`}{`'`}{`t worry. I can`}{`'`}{`t read it either. Maybe `}<a parentName="p" {...{
        "href": "https://twitter.com/sarah_edo"
      }}>{`Sarah Drasner`}</a>{` can, she`}{`'`}{`s an SVG goddess 🤔`}</p>
    <p>{`PS: she can 👇`}</p>
    <p>{`https`}{`&`}{`#x3A;//twitter.com/sarah_edo/status/971840965488119809`}</p>
    <p>{`When you build a transition, you`}{`'`}{`re trying to smoothly move from A to B. To get from `}<inlineCode parentName="p">{`0`}</inlineCode>{` to `}<inlineCode parentName="p">{`1`}</inlineCode>{`, you go through `}<inlineCode parentName="p">{`0.1`}</inlineCode>{`, `}<inlineCode parentName="p">{`0.2`}</inlineCode>{` and so on.`}</p>
    <p>{`But a path definition is more complex.`}</p>
    <p>{`You`}{`'`}{`re dealing with a bunch of numbers that have to move just right. Change them all together, and funny things may happen like arcs flying around the screen.`}</p>
    <p>{`Or an error. 😬`}</p>
    <p>{`[`}{`caption id=`}{`"`}{`attachment_8256`}{`"`}{` align=`}{`"`}{`alignnone`}{`"`}{` width=`}{`"`}{`1024`}{`"`}{`]`}<img parentName="p" {...{
        "src": "/044f339bf7d593635ce980a3442ea88b/blog-wp-content-uploads-2018-03-naive-transition-d3-pie.gif",
        "alt": "Naively implemented transition. Notice the arcs change shape sometimes"
      }}></img>{` Naively implemented transition. Notice the arcs change shape sometimes`}{`[`}{`/caption]`}</p>
    <p><img parentName="p" {...{
        "src": "/2b411e26e2545749bc89237f421c45a7/media-sDZhdjNDsIq1q-giphy.gif",
        "alt": null
      }}></img></p>
    <h2 {...{
      "id": "tweens-to-the-rescue"
    }}>{`Tweens to the rescue`}</h2>
    <p>{`Luckily, D3 lets us define custom transitions called tweens. To smoothly animate a piechart, we`}{`'`}{`re going to build an `}<inlineCode parentName="p">{`arcTween`}</inlineCode>{`. Because piecharts are made of arcs.`}</p>
    <p>{`The idea is to move from blindly transitioning path definitions to transitioning angles on a pie slice. We`}{`'`}{`re building a tween generator that takes some params and returns a tweening function.`}</p>
    <p>{`Tweening functions are what makes transitions work, by the way. They take an argument, `}<inlineCode parentName="p">{`t`}</inlineCode>{`, and return the value of your prop at that specific `}{`"`}{`time`}{`"`}{` of your transition.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px",
          "textAlign": "center",
          "fontStyle": "italic"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/5f0d85fa4ed1abe77a3cf5551c6d82f8/5a190/blog-wp-content-uploads-2018-03-transition-sketch.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70.85201793721974%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/svg+xml,%3csvg%20xmlns=\\'http://www.w3.org/2000/svg\\'%20width=\\'400\\'%20height=\\'284\\'%20viewBox=\\'0%200%20400%20284\\'%20preserveAspectRatio=\\'none\\'%3e%3cpath%20d=\\'M179%2057c0%202%200%202-2%202l-3%201-6%201-16%202c-6%201-11%202-12%201l-1%201c0%202-2%201-2-1h-1c0%202%205%2013%206%2011l-1-3c-1-3-1-3%201-3v-1c-2-1-2-1%201-2%209-3%2037-5%2036-3v1l1%203c0%202-1%203-5%203-11%201-25%203-32%206l-7%204%203-1c1-1%202%200%203%202l2%202-1-3c-1-2-1-2%203-4l24-5h8v7l-11%201-9%202%204%201%209-2%207-1v3l-2%2013c-2%207-2%2014-3%2030a15110%2015110%200%20012%2044l4-1c1-1%201-1-1-1s-2%200%201-2c4-3%203-4-1-1-4%202-4%202-3-8v-9l3%204%204%206-6-12-1-6v-5l6%209%207%2012c2%201%202%202-2%204l-4%203%205-2a1076%201076%200%200111-8l-7%204-2-2-8-13c-5-9-6-11-6-15v-5l4%203c1%203%203%204%203%203l-6-8c-1%200-1-13%201-13l3%205%204%205-6-12c-2-3-2-3%200-12%201-5%202-11%201-13-1-6%201-8%205-8%206%200%2016%202%2026%205l10%203c1-2-26-9-35-9h-4v-6l-1-8c-1-1-1-1-1%201m83%2052l2%203a528%20528%200%2001-6%206l3-2%203%205c3%205%208%2020%208%2022-1%201%200%203%201%205%202%202%202%202%202%200l1-5c0-3%200-3-1-1s-1%201-3-6c-1-6-6-15-8-18-2-2-3-3-1-3%202-1%203-1%206%206l5%207-3-8c-5-8-5-7%200-10%206-2%207-3%205%200-1%201-1%201%201%201%203-1%206%206%2010%2021%202%209%202%2010%201%2012v5c3%202%202%203-1%203l-4%201-3-10-3-11-1%202c8%2020%209%2037%204%2052-3%2011-4%2015%200%206%204-12%205-18%205-28-1-10-1-10%202-11h7l-1-1v-3c-2%201-3-1-1-3v-1l-2-4c0-4-3-15-6-22l-1-7c1%200%200-2-2-3-4-3-5-3-12%201l-4%202-2-4-3-3%202%204M78%20141c-6%2017-8%2029-6%2041%201%208%206%2023%208%2025v-2a77%2077%200%2001-1-64l3-7-4%207\\'%20fill=\\'%23d3d3d3\\'%20fill-rule=\\'evenodd\\'/%3e%3c/svg%3e')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/5f0d85fa4ed1abe77a3cf5551c6d82f8/ca0a1/blog-wp-content-uploads-2018-03-transition-sketch.webp 223w", "/static/5f0d85fa4ed1abe77a3cf5551c6d82f8/75680/blog-wp-content-uploads-2018-03-transition-sketch.webp 445w", "/static/5f0d85fa4ed1abe77a3cf5551c6d82f8/d00b9/blog-wp-content-uploads-2018-03-transition-sketch.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/5f0d85fa4ed1abe77a3cf5551c6d82f8/e92b6/blog-wp-content-uploads-2018-03-transition-sketch.png 223w", "/static/5f0d85fa4ed1abe77a3cf5551c6d82f8/e66bf/blog-wp-content-uploads-2018-03-transition-sketch.png 445w", "/static/5f0d85fa4ed1abe77a3cf5551c6d82f8/5a190/blog-wp-content-uploads-2018-03-transition-sketch.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/5f0d85fa4ed1abe77a3cf5551c6d82f8/5a190/blog-wp-content-uploads-2018-03-transition-sketch.png",
              "alt": "blog wp content uploads 2018 03 transition sketch",
              "title": "blog wp content uploads 2018 03 transition sketch",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Our tween generator is going to need:`}</p>
    <ol>
      <li parentName="ol"><inlineCode parentName="li">{`oldData`}</inlineCode>{`, the definition of our pie slice at the start of our transition`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`newData`}</inlineCode>{`, the definition of our pie slice that we want to tween towards`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`arc`}</inlineCode>{`, a `}<a parentName="li" {...{
          "href": "https://github.com/d3/d3-shape/blob/master/README.md#arcs"
        }}>{`D3 arc generator`}</a></li>
    </ol>
    <p>{`Both `}<inlineCode parentName="p">{`oldData`}</inlineCode>{` and `}<inlineCode parentName="p">{`newData`}</inlineCode>{` come from a `}<a parentName="p" {...{
        "href": "https://github.com/d3/d3-shape/blob/master/README.md#pies"
      }}>{`D3 pie generator`}</a>{`. Their `}<inlineCode parentName="p">{`startAngle`}</inlineCode>{` and `}<inlineCode parentName="p">{`endAngle`}</inlineCode>{` is what we`}{`'`}{`re interested in.`}</p>
    <p>{`Our `}<inlineCode parentName="p">{`arcTween`}</inlineCode>{` function uses these to build a tween method that we then feed into `}<inlineCode parentName="p">{`attrTween`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{}}>{`// inspired from http://bl.ocks.org/mbostock/5100636
function arcTween(oldData, newData, arc) {
    const copy = { ...oldData };
    return function() {
        const interpolateStartAngle = d3.interpolate(
                oldData.startAngle,
                newData.startAngle
            ),
            interpolateEndAngle = d3.interpolate(
                oldData.endAngle,
                newData.endAngle
            );

        return function(t) {
            copy.startAngle = interpolateStartAngle(t);
            copy.endAngle = interpolateEndAngle(t);
            return arc(copy);
        };
    };
}
`}</code></pre>
    <p>{`We make a `}<inlineCode parentName="p">{`copy`}</inlineCode>{` of `}<inlineCode parentName="p">{`oldData`}</inlineCode>{` so we don`}{`'`}{`t change input data by accident, then we return a function. This function creates two interpolators with `}<inlineCode parentName="p">{`d3.interpolate`}</inlineCode>{`.`}</p>
    <p>{`Each interpolator starts from an `}<inlineCode parentName="p">{`oldData`}</inlineCode>{` angle and moves towards a `}<inlineCode parentName="p">{`newData`}</inlineCode>{` angle. One for start, one for end.`}</p>
    <p>{`This function then returns our actual interpolation function. It takes the argument `}<inlineCode parentName="p">{`t`}</inlineCode>{`, feeds it into our two interpolators, adjusts values on the `}<inlineCode parentName="p">{`copy`}</inlineCode>{` object, feeds that into the `}<inlineCode parentName="p">{`arc`}</inlineCode>{` generator, and returns a new path definition.`}</p>
    <p><img parentName="p" {...{
        "src": "/93adb298ace578c16f01000a47c03f6c/media-3o7btPCcdNniyf0ArS-giphy.gif",
        "alt": null
      }}></img></p>
    <p>{`You use it like this 👇`}</p>
    <pre><code parentName="pre" {...{}}>{`// Piechart.js
d3
    .select(this.refs.elem)
    .transition()
    .duration(80)
    .attrTween("d", arcTween(this.state.d, newProps.d, this.arc))
    .on("end", () =>
        this.setState({
            d: newProps.d,
            pathD: this.arc(newProps.d)
        })
    );
`}</code></pre>
    <p>{`Select an element, a `}<inlineCode parentName="p">{`<path>`}</inlineCode>{`, start a transition, make it last `}<inlineCode parentName="p">{`80`}</inlineCode>{` milliseconds, `}<inlineCode parentName="p">{`attrTween`}</inlineCode>{` the path definition, `}<inlineCode parentName="p">{`d`}</inlineCode>{`, attribute using the tween returned from `}<inlineCode parentName="p">{`arcTween`}</inlineCode>{`.`}</p>
    <p>{`Better? 🤪`}</p>
    <p>{`Let`}{`'`}{`s put it to use in a piechart. We`}{`'`}{`re using `}<a parentName="p" {...{
        "href": "https://swizec.com/reactd3js"
      }}>{`React and D3`}</a>{` because React makes dataviz code easier to understand.`}</p>
    <p>{`We build our piechart from 2 components:`}</p>
    <ol>
      <li parentName="ol">{`Piechart – takes data, feeds into a `}<inlineCode parentName="li">{`d3.pie()`}</inlineCode>{` generator, renders a bunch of arcs in a loop`}</li>
      <li parentName="ol">{`Arc - takes data for an arc, feeds it into a `}<inlineCode parentName="li">{`d3.arc()`}</inlineCode>{` generator, renders a `}<inlineCode parentName="li">{`<path>`}</inlineCode>{` element, handles transitions`}</li>
    </ol>
    <p>{`You can see the `}<a parentName="p" {...{
        "href": "https://github.com/Swizec/react-d3-pie"
      }}>{`full code on GitHub`}</a></p>
    <h2 {...{
      "id": ""
    }}></h2>
    <p>{`The Piechart component itself is pretty simple. Takes some data, renders some arcs.`}</p>
    <pre><code parentName="pre" {...{}}>{`// Piechart.js
class Piechart extends Component {
    pie = d3
        .pie()
        .value(d => d.amount)
        .sortValues(d => d.tag)
        .padAngle(0.005);

    render() {
        const { data, groupBy, x, y, color } = this.props;

        const _data = groupByFunc(data, groupBy);

        return (
            
                {this.pie(_data).map((d, i) => (
                    
                ))}
                
                    {data.length}
                
                
                    datapoints
                
            
        );
    }
}
`}</code></pre>
    <p>{`We define a pie generator with a value accessor `}<inlineCode parentName="p">{`d => d.amount`}</inlineCode>{` that sorts arcs by `}<inlineCode parentName="p">{`d.tag`}</inlineCode>{`, and adds a padding of `}<inlineCode parentName="p">{`0.005`}</inlineCode>{` between arcs. To learn more about how padding works, check out this wonderful `}<a parentName="p" {...{
        "href": "https://bl.ocks.org/mbostock/3e961b4c97a1b543fff2"
      }}>{`pie padding animation`}</a>{` by Mike Bostock.`}</p>
    <p>{`The `}<inlineCode parentName="p">{`render`}</inlineCode>{` method groups `}<inlineCode parentName="p">{`data`}</inlineCode>{` by a given `}<inlineCode parentName="p">{`groupBy`}</inlineCode>{` function, in our case by tag, then outputs a grouping element `}<inlineCode parentName="p">{`<g>`}</inlineCode>{` inside it.`}</p>
    <ol>
      <li parentName="ol">{`Loops through the output of `}<inlineCode parentName="li">{`this.pie(_data)`}</inlineCode>{` and renders an `}<inlineCode parentName="li">{`<Arc>`}</inlineCode>{` for each value`}</li>
      <li parentName="ol">{`Creates two `}<inlineCode parentName="li">{`<text>`}</inlineCode>{` nodes for the center of our piechart`}</li>
    </ol>
    <p>{`How data makes it into `}<inlineCode parentName="p">{`<Piechart>`}</inlineCode>{` is outside the scope of this tutorial. You can assume data comes as an array that changes every couple milliseconds. This triggers a re-render, which propagates into our `}<inlineCode parentName="p">{`<Arc>`}</inlineCode>{` components.`}</p>
    <p>{`You can read that code on GitHub in `}<a parentName="p" {...{
        "href": "https://github.com/Swizec/react-d3-pie/blob/master/src/App.js"
      }}>{`the App component`}</a>{`.`}</p>
    <h2 {...{
      "id": "-1"
    }}></h2>
    <p>{`Our `}<inlineCode parentName="p">{`<Piechart>`}</inlineCode>{` gets updated data every few milliseconds and re-renders. This change propagates into `}<inlineCode parentName="p">{`<Arc>`}</inlineCode>{` components via props.`}</p>
    <p>{`That means `}<inlineCode parentName="p">{`<Arc>`}</inlineCode>{` has to handle transitions. Pushing transitions into the `}<inlineCode parentName="p">{`<Arc>`}</inlineCode>{` component means we can preserve React`}{`'`}{`s ideal of declarative rendering. Piechart just renders Arcs and gives them info. Arcs handle everything about rendering pie arcs. Even transitions.`}</p>
    <p>{`The general approach comes from my `}<a parentName="p" {...{
        "href": "https://swizec.com/reactd3js"
      }}>{`React+D3v4 book`}</a>{`:`}</p>
    <ol>
      <li parentName="ol">{`Move props into state`}</li>
      <li parentName="ol">{`Use state to render`}</li>
      <li parentName="ol">{`Transition raw attributes with D3`}</li>
      <li parentName="ol">{`Update state when transition ends`}</li>
    </ol>
    <p>{`The outline for our `}<inlineCode parentName="p">{`<Arc>`}</inlineCode>{` component looks like this 👇`}</p>
    <pre><code parentName="pre" {...{}}>{`// Piechart.js

class Arc extends Component {
    arc = d3
        .arc()
        .innerRadius(80)
        .outerRadius(150)
        .cornerRadius(8);

    constructor(props) {
        super(props);

        this.state = {
            color: props.color,
            origCol: props.color,
            d: props.d,
            pathD: this.arc(props.d)
        };
    }

    componentWillReceiveProps(newProps) {
        // transition, state update
    }

    // hover/unhover color changes via this.setState

    render() {
        const { color, pathD } = this.state;

        return (
            
        );
    }
}
`}</code></pre>
    <p>{`Start with an `}<inlineCode parentName="p">{`arc`}</inlineCode>{` generator that takes data and returns path definitions. Ours has an innerRadius of `}<inlineCode parentName="p">{`80`}</inlineCode>{`, an outerRadius of `}<inlineCode parentName="p">{`150`}</inlineCode>{`, and rounded corners.`}</p>
    <p>{`In the `}<inlineCode parentName="p">{`constructor`}</inlineCode>{`, we copy `}{`\\`}{`important props to `}<inlineCode parentName="p">{`this.state`}</inlineCode>{`. A good choice are props that we later intend to change. Color on hover/unhover, and `}<inlineCode parentName="p">{`d`}</inlineCode>{` and `}<inlineCode parentName="p">{`pathD`}</inlineCode>{` on input data changes.`}</p>
    <p><inlineCode parentName="p">{`pathD`}</inlineCode>{` is the part we`}{`'`}{`re going to transition. It`}{`'`}{`s the output of calling `}<inlineCode parentName="p">{`this.arc`}</inlineCode>{` on `}<inlineCode parentName="p">{`this.state.d`}</inlineCode>{`.`}</p>
    <p><inlineCode parentName="p">{`componentWillReceiveProps`}</inlineCode>{` is where that transition is going to happen.`}</p>
    <p><inlineCode parentName="p">{`render`}</inlineCode>{` doesn`}{`'`}{`t do much. It outputs a `}<inlineCode parentName="p">{`<path>`}</inlineCode>{` element with a ref of `}<inlineCode parentName="p">{`elem`}</inlineCode>{`. It also defines mouse event handlers.`}</p>
    <h2 {...{
      "id": "adding-a-d3-transition-to-a-react-component"
    }}>{`Adding a D3 transition to a React component`}</h2>
    <p>{`Now that `}<inlineCode parentName="p">{`<Arc>`}</inlineCode>{` is rendering from state, we can use D3 transitions to make updates smoother. That happens inside `}<inlineCode parentName="p">{`componentWillReceiveProps`}</inlineCode>{`.`}</p>
    <p>{`Our goal is to take the new props, use D3 to transition appropriate attributes on the base DOM node, then update state with the new values to ensure React`}{`'`}{`s engine knows what`}{`'`}{`s going on.`}</p>
    <pre><code parentName="pre" {...{}}>{`// Piechart.js in 

    componentWillReceiveProps(newProps) {
        this.setState({
            color: newProps.color
        });

        d3
            .select(this.refs.elem)
            .transition()
            .duration(80)
            .attrTween("d", arcTween(this.state.d, newProps.d, this.arc))
            .on("end", () =>
                this.setState({
                    d: newProps.d,
                    pathD: this.arc(newProps.d)
                })
            );
    }
`}</code></pre>
    <p>{`We update color state right away. This triggers a render, eventually.`}</p>
    <p>{`Then we start an 80 millisecond transition that uses the `}<inlineCode parentName="p">{`arcTween`}</inlineCode>{` generator we built earlier. When the transition ends, we update React state with the new values for `}<inlineCode parentName="p">{`d`}</inlineCode>{` and `}<inlineCode parentName="p">{`pathD`}</inlineCode>{`. This triggers another render.`}</p>
    <p>{`That might sound like a lot of renders, but it works okay. Don`}{`'`}{`t worry about wasting resources, you`}{`'`}{`re re-rendering a single `}<inlineCode parentName="p">{`<path>`}</inlineCode>{` element. `}<a parentName="p" {...{
        "href": "https://swizec.com/blog/building-interactive-dom-benchmark-preliminary-results/swizec/8219"
      }}>{`The DOM is pretty fast`}</a>{` ☺️`}</p>
    <p>{`[`}{`caption id=`}{`"`}{`attachment_8257`}{`"`}{` align=`}{`"`}{`alignnone`}{`"`}{` width=`}{`"`}{`1024`}{`"`}{`]`}<img parentName="p" {...{
        "src": "/59ee3958dc6d90b57613447fc5ad251d/blog-wp-content-uploads-2018-03-transition-pie-1.gif",
        "alt": "Our pie looks nice and smooth."
      }}></img>{` Our pie looks nice and smooth.`}{`[`}{`/caption]`}</p>
    <h2 {...{
      "id": "recap"
    }}>{`Recap`}</h2>
    <p>{`You learned how to build a silky animated smooth piechart with React and D3! Yay! `}{`\\`}{`o/`}</p>
    <p>{`In a nutshell 👇`}</p>
    <ul>
      <li parentName="ul">{`Use custom tweens to transition complex shapes`}</li>
      <li parentName="ul">{`Render from state`}</li>
      <li parentName="ul">{`Always update state after transitions end`}</li>
    </ul>
    <p>{`🤘 Party hard 🤘`}</p>
    <p>{`To learn more about using React and D3 to write declarative data visualization code, read my book `}<a parentName="p" {...{
        "href": "https://swizec.com/reactd3js"
      }}>{`React+D3v4`}</a>{`. I`}{`'`}{`m probably adding this as a new chapter.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      